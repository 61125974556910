<template>
  <v-container
    id="icons"
    fluid
  >
    <!--  -->
    <v-row
      align="center"
      justify="center"
      class="d-none d-sm-flex"
    >
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="warning"
          icon="mdi-chart-bar-stacked"
          title="Event"
          :value="String(summary.total)"
          style="cursor: pointer;"
          @click.native="setStatus('')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="rgb(233, 30, 99)"
          icon="mdi-redo-variant"
          title="Akan Datang"
          :value="String(summary.upcoming)"
          style="cursor: pointer;"
          @click.native="setStatus('upcoming')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-update"
          title="Berjalan"
          :value="String(summary.ongoing)"
          style="cursor: pointer;"
          @click.native="setStatus('ongoing')"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-undo-variant"
          title="Terlewat"
          :value="String(summary.missed)"
          style="cursor: pointer;"
          @click.native="setStatus('missed')"
        />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="d-flex d-sm-none"
    >
      <!--  -->
      <v-col
        cols="12"
        style="padding: 0"
      >
        <!--  -->
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            <v-badge
              color="green"
              :content="item.summary"
            >
              {{ item.title }}
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
          >
            <v-card flat>
              <v-card-text>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <base-material-stats-card
                    color="info"
                    :icon="item.icon"
                    :title="item.title"
                    :value="String(item.summary)"
                    style="cursor: pointer;"
                    @click.native="setStatus(item.status)"
                  />
                </v-col>
                {{ item.content }}
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
      >
        <v-text-field
          v-model="q"
          placeholder="Cari Judul Event"
          append-icon="mdi-magnify"
          :label="$t('search')"
          color="secondary"
          hide-details
          @keyup="search"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        md="2"
        lg="2"
        offset-md="6"
        offset-lg="6"
      >
        <v-btn
          to="/event/create"
          left
          width="100%"
          color="primary"
        >
          <span class="mr-1">
            <v-icon light>mdi-pencil-plus</v-icon>
          </span>
          Buat Baru
        </v-btn>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="12"
      >
        <base-material-card
          icon="mdi-calendar-check"
          :title="status !== '' ? 'Event - ' + status : 'Event - All'"
          style="text-transform: capitalize"
          color="primary"
          class="px-6 py-3"
        >
          <template>
            <div
              v-if="!isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <div v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left primary--text">
                        #
                      </th>
                      <th class="text-left primary--text">
                        Judul
                      </th>
                      <th class="text-left primary--text">
                        Lokasi
                      </th>
                      <th class="text-left primary--text">
                        Tgl.Mulai
                      </th>
                      <th class="text-left primary--text">
                        Jam.Mulai
                      </th>
                      <th class="text-left primary--text">
                        Tgl.Selesai
                      </th>
                      <th class="text-left primary--text">
                        Jam.Selesai
                      </th>
                      <th class="text-left primary--text">
                        Dibuat Pada
                      </th>
                      <th class="text-left primary--text">
                        Status
                      </th>
                      <th class="text-center primary--text">
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <!--  -->
                  <tbody>
                    <tr
                      v-for="(data, index) in event.data"
                      :key="data.id"
                    >
                      <td>{{ index + event.meta.from }}</td>
                      <td>{{ data.title }}</td>
                      <td>{{ data.location }}</td>
                      <td>
                        {{ data.start_date ? data.start_date : ' - ' }}
                      </td>
                      <td>
                        {{ data.start_time ? data.start_time : ' - ' }}
                      </td>
                      <td>
                        {{ data.end_date ? data.end_date : ' - ' }}
                      </td>
                      <td>
                        {{ data.end_time ? data.end_time : ' - ' }}
                      </td>
                      <td>{{ data.created_at | moment('D MMM YYYY') }}</td>
                      <td tyle="text-transform: capitalize">
                        {{ data.status_name }}
                      </td>
                      <td class="text-center">
                        <v-tooltip
                          right
                          color="info"
                        >
                          <!--  -->
                          <template v-slot:activator="{ on, attrs }">
                            <!--  -->
                            <v-btn
                              icon
                              color="blue"
                              :to="'/event/' + data.id + '/show'"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small>
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>View</span>
                        </v-tooltip>

                        <v-tooltip
                          left
                          color="red"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <!--  -->
                            <v-btn
                              color="red lighten-2"
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="openDialog(data.id, data.title)"
                            >
                              <v-icon small>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="event.meta.last_page"
                  total-visible="5"
                  @input="getResults"
                />
              </div>
            </div>
          </template>
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <template>
          <v-dialog
            v-model="dialog.open"
            width="500"
            :retain-focus="false"
          >
            <v-card>
              <v-card-title primary-title>
                Confirmation
              </v-card-title>

              <v-card-text>
                Anda yakin akan menghapus data event dengan judul "{{
                  dialog.title
                }}"?
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="dialog.open = false"
                >
                  BATAL
                </v-btn>
                <v-spacer />
                <v-btn
                  color="error"
                  @click="deleteItem(dialog.id)"
                >
                  HAPUS
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'Event',

    data: function () {
      return {
        event: {
          meta: {},
          data: {},
        },
        summary: {
          total: 0,
          upcoming: 0,
          ongoing: 0,
          missed: 0,
        },
        items: [
          {
            tab: 'One',
            title: 'Event',
            icon: 'mdi-send',
            summary: '0',
            status: '',
          },
          {
            tab: 'Two',
            title: 'Akan Datang',
            icon: 'mdi-send-check',
            summary: '0',
            status: 'upcoming',
          },
          {
            tab: 'Three',
            title: 'Berjalan',
            icon: 'mdi-send-lock',
            summary: '0',
            status: 'ongoing',
          },
          {
            tab: 'Four',
            title: 'Terlewat',
            icon: 'mdi-trash-can',
            summary: '0',
            status: 'missed',
          },
        ],
        tab: null,
        isLoading: false,
        dialog: {
          open: false,
          id: 0,
          title: '',
        },
        page: parseInt(this.$route.query.page) || 1,
        q: this.$route.query.q || '',
        offset: this.$route.query.offset || 10,
        status: this.$route.query.status || '',
      }
    },
    watch: {
      q (newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } })
      },
      '$route.query.q': function (val) {
        this.q = val
      },
      page (newVal) {
        this.$$router.query.push({
          query: { ...this.$route.query, page: newVal },
        })
      },
      '$route.query.page': function (val) {
        this.page = val
      },
      offset (newVal) {
        this.$router.query.push({
          query: { ...this.$route.query, offset: newVal },
        })
      },
      '$route.query.offset': function (val) {
        this.offset = val
      },
      status (newVal) {
        this.$router.push({ query: { ...this.$route.query, status: newVal } })
      },
      '$route.query.status': function (val) {
        this.status = val
      },
    },
    mounted () {
      this.getSummary()
      this.getResults()
    },
    methods: {
      openDialog (id, title) {
        this.dialog.id = id
        this.dialog.title = title
        this.dialog.open = true
        console.log(this.dialog.id)
      },
      deleteItem (id) {
        this.dialog.open = false
        axios.delete('/v1/post/event/' + id).then(response => {
          if (response.data.meta.status) {
            this.$toast.error('Berhasil menghapus data', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          } else {
            console.log(response.data)
          }

          // Update
          this.getResults()
        })
      },
      setStatus (val) {
        this.status = val
        this.getResults()
      },
      setSummary (data) {
        this.summary = data
        this.items.forEach(item => {
          if (item.status === '') {
            item.summary = this.summary.total
          } else if (item.status === 'upcoming') {
            item.summary = this.summary.upcoming
          } else if (item.status === 'ongoing') {
            item.summary = this.summary.ongoing
          } else if (item.status === 'missed') {
            item.summary = this.summary.missed
          } else {
            item.summary = 0
          }
        })
      },
      getSummary () {
        axios
          .get('/v1/post/event/summary')
          .then(response => {
            this.setSummary(response.data.data)
          })
          .catch(e => {
            console.error(e)
          })
      },
      search () {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.getResults()
        }, 700)
      },
      setEvent (data) {
        this.event = data
      },
      // Our method to GET results from a API endpoint
      getResults (page = this.page) {
        this.isLoading = false
        let search = ''
        if (this.q !== '') {
          search = '?filter[title][like]=' + this.q
        }
        const params = {
          status: this.status,
          page: page,
          sort: '-id',
          entities: 'image,thumbnail,category',
          offset: this.offset,
        }
        axios
          .get('/v1/post/event' + search, {
            params: params,
          })
          .then(response => {
            this.setEvent(response.data)
            this.isLoading = true
          })
      },
    },
  }
</script>
